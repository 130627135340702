<template>
    <div class="app-container">
        <el-card v-loading="loading">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>
                        <el-select v-model="rootTenantId" placeholder="请选择" @change="changeTenant">
                            <el-option v-for="item in tenants" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item style="line-height: 30px">设备绑定管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号/车牌号/VIN">
                            <el-input v-model="queryParams.condition.search" placeholder="请输入设备号/车牌号/VIN" clearable />
                        </el-form-item>
                        <el-form-item label="设备状态">
                            <el-select v-model="queryParams.condition.bindStatus" placeholder="请选择变更状态" clearable>
                                <el-option label="绑定" value="bind" key="bind"></el-option>
                                <el-option label="解除绑定" value="unbind" key="unbind"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择分公司">
                            <el-select v-model="queryParams.condition.subTenantId" placeholder="请选择分公司" clearable>
                                <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="选择车队">
                            <el-select v-model="queryParams.condition.fleetId" placeholder="选择车队">
                                <el-option
                                    v-for="item in fleetList"
                                    :key="item.tenantId"
                                    :label="item.tenantName"
                                    :value="item.tenantId">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="筛选日期">
                            <el-date-picker v-model="dataRange" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <div>
                <div style="text-align: right; height: 40px">
                    <el-link :href="baseURL + '/operate/api/device/bindRecordTemplate'" type="primary">下载导入模板：设备绑定及解绑管理模板.xlsx</el-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-button @click="uploadRecords" type="primary" icon="el-icon-upload2" size="mini">批量导入</el-button>
                    <el-button @click="addRecord" type="primary" icon="el-icon-plus" size="mini">新增变更记录</el-button>
                    <el-button @click="downloadTable" icon="el-icon-download" type="primary" size="mini"></el-button>
                </div>
                <el-table :data="bindRecordList">
                    <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber">
                        <template slot-scope="scope">
                            <el-link @click="search(scope.row.deviceNumber)" type="primary">{{ scope.row.deviceNumber }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="绑定状态" align="center" key="bindStatus">
                        <template slot-scope="scope">
                            {{ scope.row.bindStatus == 'bind' ? '绑定' : '解除绑定' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="绑定/解绑日期" align="center" key="bindTime" prop="bindTime" />
                    <el-table-column label="车架号" align="center" key="vin" prop="vin">
                        <template slot-scope="scope">
                            <el-link @click="search(scope.row.vin)" type="primary">{{ scope.row.vin }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="车牌号" align="center" key="plateNo" prop="plateNo">
                        <template slot-scope="scope">
                            <el-link @click="search(scope.row.plateNo)" type="primary">{{ scope.row.plateNo }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属分公司" align="center" key="subTenantName" prop="subTenantName" />
                    <el-table-column label="所属总公司" align="center" key="rootTenantName" prop="rootTenantName">
                        {{ rootTenantName }}
                    </el-table-column>
                    <el-table-column label="操作人" align="center" key="operator" prop="operator" />
                    <el-table-column label="操作时间" align="center" key="createTime" prop="createTime" />
                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="fetchBindRecordList" />
            </div>
            <!-- 添加弹窗 -->
            <el-dialog :modal-append-to-body="false" title="新增变更记录" :visible.sync="recordFormVisible" width="400px" @close="doAddBindRecord(false)">
                <el-form :model="bindRecordForm" ref="bindRecordForm" size="small" label-width="100px" :rules="rules">
                    <el-form-item label="设备号" prop="deviceNumber">
                        <el-input v-model="bindRecordForm.deviceNumber" maxlength="15" placeholder="请输入设备号" clearable />
                    </el-form-item>
                    <el-form-item label="状态变更" prop="bindStatus">
                        <el-select style="width: 100%" v-model="bindRecordForm.bindStatus" placeholder="请选择变更状态">
                            <el-option label="绑定" value="bind" key="bind"></el-option>
                            <el-option label="解除绑定" value="unbind" key="unbind"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="车牌号" prop="plateNo">
                        <el-input v-model="bindRecordForm.plateNo" maxlength="8" placeholder="请输入设备号" clearable />
                    </el-form-item>
                    <el-form-item label="车架号" prop="vin">
                        <el-input v-model="bindRecordForm.vin" maxlength="17" placeholder="请输入设备号" clearable />
                    </el-form-item>
                    <el-form-item label="选择分公司" prop="subTenantId">
                        <el-select style="width: 100%" v-model="bindRecordForm.subTenantId" placeholder="请选择分公司" clearable>
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="变更日期" prop="bindTime">
                        <el-date-picker style="width: 100%" v-model="bindRecordForm.bindTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择变更日期"> </el-date-picker>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="doAddBindRecord(false)">取 消</el-button>
                    <el-button type="primary" @click="doAddBindRecord(true)">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 添加批次对话框 -->
            <el-dialog :title="title" :visible.sync="uploadRecordsVisible" width="600px" append-to-body>
                <el-form ref="uploadForm" :model="uploadForm" label-width="120px">
                    <el-form-item label="设备Excel" prop="file">
                        <el-upload class="upload-demo" ref="upload" :file-list="fileList" :on-change="handleFileChange" :on-remove="handleRemove" :disabled="loading" :auto-upload="false">
                            <el-button size="small" type="primary" :disabled="loading">选择文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="beforeUpload(file)" :disabled="loading">确 定</el-button>
                    <el-button @click="uploadRecordsVisible = false">取 消</el-button>
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'bindManager',
    data() {
        return {
            title: '添加',
            loading: false,
            recordFormVisible: false,
            uploadRecordsVisible: false,
            tenants: [
                { label: '环球车享', value: 10004 },
                { label: '太保商用车', value: 10049 }
            ],
            rootTenantId: 10004,
            rootTenantName: '环球车享',
            companyList: [],
            fleetList: [],
            dataRange: [],
            queryParams: {
                condition: {},
                pageNo: 1,
                pageSize: 10
            },

            bindRecordList: [],
            total: 0,
            bindRecordForm: {
                deviceNumber: null,
                bindStatus: null,
                plateNo: null,
                vin: null,
                subTenantId: null,
                bindTime: null
            },
            uploadForm: {},
            fileList: [],
            rules: {
                deviceNumber: [
                    { required: true, message: '设备号不能为空', trigger: 'blur' },
                    { min: 10, max: 15, message: '设备号长度在 10 到 15 位', trigger: 'blur' }
                ],
                bindStatus: [{ required: true, message: '变更状态不能为空', trigger: 'blur' }],
                vin: [
                    { required: true, message: '车架号不能为空', trigger: 'blur' },
                    { min: 17, max: 17, message: '车架号长度为17位', trigger: 'blur' }
                ],
                plateNo: [
                    { required: true, message: '车牌号不能为空', trigger: 'blur' },
                    { min: 7, max: 8, message: '车牌号长度在 7 到 8 位', trigger: 'blur' }
                ],
                subTenantId: [{ required: true, message: '分公司不能为空', trigger: 'blur' }],
                bindTime: [{ required: true, message: '变更时间不能为空', trigger: 'blur' }]
            },
            baseURL: ''
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        this.fetchCompanyList();
        this.fetchBindRecordList();
    },
    methods: {
        downloadTemplate() {},
        uploadRecords() {
            this.fileList = [];
            this.file = null;
            this.uploadRecordsVisible = true;
        },

        handleFileChange(file, fileList) {
            this.file = file.raw;
            this.fileList = [this.file];
        },
        handleRemove(file, fileList) {
            this.file = undefined;
            this.fileList = [];
        },
        beforeUpload(file) {
            //this.disabled = true;
            let _this = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                _this.fileString = reader.result.replace('data:', '').replace(/^.+,/, '');
                _this.doUploadRecord(_this.fileString);
            };
            //this.disabled = false;
            return false;
        },

        doUploadRecord(fileContent) {
            this.loading = true;
            const data = {
                file: fileContent,
                rootTenantId: this.rootTenantId
            };
            this.instance
                .post('/operate/api/device/addBindRecordBatch', data)
                .then((res) => {
                    this.loading = false;
                    if (res.data.code === 200) {
                        this.$message.success('上传成功');
                        this.resetWithLoadRecord();
                        this.uploadRecordsVisible = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    this.$message.error('请求失败! 请稍后重试');
                });
        },

        addRecord() {
            this.recordFormVisible = true;
        },
        doAddBindRecord(submitFlag) {
            if (submitFlag) {
                this.$refs['bindRecordForm'].validate((valid) => {
                    if (valid) {
                        this.submitBindRecord();
                    }
                });
            } else {
                this.$refs.bindRecordForm && this.$refs.bindRecordForm.resetFields();
                this.recordFormVisible = false;
            }
        },
        submitBindRecord() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.bindRecordForm));
            data['rootTenantId'] = this.rootTenantId;
            this.instance.post('/operate/api/device/addBindRecord', data).then((res) => {
                this.loading = false;
                if (res.data.code == 200) {
                    this.recordFormVisible = false;
                    this.$message.success('提交成功!');
                    this.resetWithLoadRecord();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        downloadTable() {
            let searchStr = 'rootTenantId=' + this.rootTenantId;
            let params = this.queryParams.condition;
            if (params.search) {
                searchStr = searchStr + '&search=' + params.search;
            }
            if (params.bindStatus) {
                searchStr = searchStr + '&bindStatus=' + params.bindStatus;
            }
            if (params.subTenantId) {
                searchStr = searchStr + '&subTenantId=' + params.subTenantId;
            }
            if (params.bindTimeMin) {
                searchStr = searchStr + '&bindTimeMin=' + params.bindTimeMin;
            }
            if (params.bindTimeMax) {
                searchStr = searchStr + '&bindTimeMax=' + params.bindTimeMax;
            }
            window.open(this.baseURL + '/operate/api/device/downloadBindRecordsExcel?' + searchStr, '_blank');
        },
        fetchCompanyList() {
            this.instance.get('/operate/api/tenant/companyList?rootTenantId=' + this.rootTenantId).then((res) => {
                this.companyList = res.data.data;
            });
        },
        fetchBindRecordList() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.queryParams));
            if (this.dataRange.length == 2) {
                if (this.dataRange[1]) data.condition.bindTimeMax = this.dataRange[1] + ' 23:59:59';
                if (this.dataRange[0]) data.condition.bindTimeMin = this.dataRange[0] + ' 00:00:00';
            }
            data.condition.rootTenantId = this.rootTenantId;
            this.instance.post('/operate/api/device/bindRecords', data).then((res) => {
                this.loading = false;
                this.bindRecordList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        search(searchText) {
            this.reset();
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    search: searchText
                }
            };
            this.fetchBindRecordList();
        },
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.fetchBindRecordList();
        },
        resetQuery() {
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {}
            };
            this.dataRange = [];
        },
        reset() {
            this.resetQuery();
            this.bindRecordList = [];
            this.fileList = [];
            this.file = undefined;
            this.bindRecordForm = {};
        },
        changeTenant(rootTenantId) {
            this.rootTenantId = rootTenantId;
            this.rootTenantName = rootTenantId == 10004 ? '环球车享' : '太保商用车';
            this.fetchCompanyList();
            this.resetWithLoadRecord();
        },
        resetWithLoadRecord() {
            this.reset();
            this.fetchBindRecordList();
        }
    }
};
</script>