<template>
    <div class="app-container">
        <el-card v-loading="loading">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="line-height: 30px">设备行程管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号">
                            <el-input v-model="queryParams.condition.deviceNumber" placeholder="请输入设备号" clearable />
                        </el-form-item>
                        <el-form-item label="行程类型">
                            <el-select v-model="queryParams.condition.version" placeholder="选择行程类型" clearable>
                                <el-option label="优化行程审核" value="optimize" key="optimize"></el-option>
                                <el-option label="正常" value="normal" key="normal"></el-option>
                                <el-option label="不可用行程" value="invalid" key="invalid"></el-option>
                                <el-option label="超短行程" value="short" key="short"></el-option>
                                <el-option label="多段合并" value="merge" key="merge"></el-option>
                                <el-option label="异常行程" value="abnormal" key="abnormal"></el-option>
                                <el-option label="路径规划异常行程" value="need_check" key="need_check"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="行程起始时间">
                            <el-date-picker v-model="dataRange" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="区间开始时间" end-placeholder="区间结束时间"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <el-table :data="tripList">
                <el-table-column label="ID" align="center" key="id" prop="id" />
                <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" />
                <el-table-column label="开始时间" align="center" key="startTime" prop="startTime">
                    <template slot-scope="scope">
                        {{ formattedTime(scope.row.startTime) }}
                    </template>
                </el-table-column>
                <el-table-column label="结束时间" align="center" key="endTime" prop="endTime">
                    <template slot-scope="scope">
                        {{ formattedTime(scope.row.endTime) }}
                    </template>
                </el-table-column>
                <el-table-column label="里程(公里)" align="center" key="mileage" prop="mileage" />
                <el-table-column label="时长(分钟)" align="center" key="drivingDuration" prop="drivingDuration" />
                <el-table-column label="点位个数" align="center" key="totalValidGpsNum" prop="totalValidGpsNum" />
                <el-table-column label="操作" align="center" key="operate" prop="operate">
                    <template slot-scope="scope">
                        <el-link @click="showTrip(scope.row.id)" type="primary">轨迹查看</el-link> |
                        <el-link @click="passCheckTrip(scope.row.id)" type="primary" v-if="queryParams.condition.version == 'need_check'">通过</el-link>
                        <el-link @click="optimizeCheckTrip(scope.row.id)" type="primary" v-if="queryParams.condition.version == 'optimize'">标记成功</el-link> |
                        <el-link @click="optimizeFailedTrip(scope.row.id)" type="primary" v-if="queryParams.condition.version == 'optimize'">标记失败</el-link>

                    </template>
                </el-table-column>
            </el-table>
            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="fetchTripList" />
        </el-card>
        <el-dialog title="行程轨迹" 
            :visible.sync="showMap" 
            fullscreen="true" 
            :modal="false" 
            append-to-body="true"
            :before-close="handleClose"
            v-loading="pointLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        > 
            <div id="map_view" style="width: 100%; height: 800px"></div>
        </el-dialog>
        
    </div>
</template>

<script>
export default {
    name: 'TripManager',
    data() {
        return {
            loading: false,
            pointLoading: false,
            showMap: false,
            tripList: [],
            total: 0,
            queryParams: {
                condition: {},
                pageNo: 1,
                pageSize: 20
            },
            dataRange: [],
            baseURL: ''
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;

        //this.map.disableAutoResize();
        //this.map.enableDragging(false);
        this.fetchTripList();
    },
    methods: {
        passCheckTrip(id) {
            this.$confirm('是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance.get('/operate/api/trip/passCheckTrip?tripId=' + id).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '已成功放行!'
                        });
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        optimizeCheckTrip(id) {
            this.$confirm('是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance.get('/operate/api/trip/optimize?result=success&tripId=' + id).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '已成功放行!'
                        });
                        this.fetchTripList()
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        optimizeFailedTrip(id) {
            this.$confirm('是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance.get('/operate/api/trip/optimize?result=error&tripId=' + id).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '已标记失败!'
                        });
                        this.fetchTripList()
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        showTrip(id) {
            this.showMap = true;
            this.renderMap(id);
        },
        renderMap(id) {
            this.map = new BMapGL.Map('map_view');
            // 创建地图实例

            // 设置地图中心点坐标和缩放级别
            var point = new BMapGL.Point(116.404, 39.915); // 北京市中心
            this.map.centerAndZoom(point, 15); // 设置中心点坐标和缩放级别

            this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
            var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
            this.map.addControl(scaleCtrl);
            var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
            this.map.addControl(zoomCtrl);

            var mp = this.map;
            var url = '/operate/api/trip/point/list?tripId=' + id;
            if (this.queryParams.condition.version == 'optimize') {
                url = '/operate/api/trip/corrected_point/list?tripId=' + id;
            }
            this.pointLoading = true;
            var this_ = this;
            this.instance.get(url).then((res) => {
                const points = res.data.data;
                this_.pointLoading = false;

                mp.clearOverlays();
                mp.centerAndZoom(new BMapGL.Point(116.404, 39.915), 15); // 设置中心点坐标和缩放级别

                const dotStyle0 = {
                    size: 10,
                    shape: 'BMAP_SHAPE_CIRCLE',
                    fillColor: 'red'
                };
                const dotStyle1 = {
                    size: 10,
                    shape: 'BMAP_SHAPE_CIRCLE',
                    fillColor: 'green'
                };
                const labelStyle = {
                    color: '#000',
                    fontSize: '14px',
                    border: '2px solid #1E90FF'
                };
                var maxLon = 0;
                var minLon = 999999;
                var maxLat = 0;
                var minLat = 999999;
                const point0 = [];
                const point1 = [];
                for (let i = 0; i < points.length; i++) {
                    const point = points[i];

                    var lon = point.lon,
                        lat = point.lat;
                    if (lon > maxLon) maxLon = lon;
                    if (lon < minLon) minLon = lon;
                    if (lat > maxLat) maxLat = lat;
                    if (lat < minLat) minLat = lat;

                    const bdPoint = new BMapGL.Point(lon, lat);
                    let pointMark = undefined;

                    if (point.s == 1) {
                        pointMark = new BMapGL.Marker3D(bdPoint, 0, dotStyle1);
                        point1.push(bdPoint);
                    } else {
                        pointMark = new BMapGL.Marker3D(bdPoint, 0, dotStyle0);
                        point0.push(bdPoint);
                    }
                    const content = `时间：${this.formattedTime(point.st)}<br>卫星数：${point.sc}<br>速度：${point.sp.toFixed(2)}`;
                    const label = new BMapGL.Label(content, {
                        // 创建文本标注
                        position: bdPoint // 设置标注的地理位置
                    });
                    label.setStyle(labelStyle);
                    pointMark.addEventListener('mouseover', () => {
                        mp.addOverlay(label);
                    });
                    pointMark.addEventListener('mouseout', () => {
                        mp.removeOverlay(label);
                    });
                    mp.addOverlay(pointMark);
                }

                mp.setViewport([new BMapGL.Point(maxLon, maxLat), new BMapGL.Point(maxLon, minLat), new BMapGL.Point(minLon, maxLat), new BMapGL.Point(minLon, minLat)]);

                var polyline0 = new BMapGL.Polyline(point0, { strokeColor: 'red', strokeWeight: 2 });
                mp.addOverlay(polyline0);
                var polyline1 = new BMapGL.Polyline(point1, { strokeColor: 'green', strokeWeight: 2 });
                mp.addOverlay(polyline1);
            });
        },
        handleClose() {
            this.showMap = false;
        },
        formattedTime(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hour = ('0' + date.getHours()).slice(-2);
            const minute = ('0' + date.getMinutes()).slice(-2);
            const second = ('0' + date.getSeconds()).slice(-2);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        },
        fetchTripPoints(id, callback) {
            this.instance.get('/operate/api/trip/point/list?tripId=' + id).then((res) => {
                callback.apply(this, res.data.data);
            });
        },
        fetchTripList() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.queryParams));
            if (this.dataRange.length == 2) {
                if (this.dataRange[1]) data.condition.endTime = this.dataRange[1];
                if (this.dataRange[0]) data.condition.startTime = this.dataRange[0];
            }

            this.instance.post('/operate/api/trip/tripList', data).then((res) => {
                this.loading = false;
                this.tripList = res.data.data.list;
                this.total = res.data.data.total;
            });
        },
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.fetchTripList();
        },
        resetQuery() {}
    }
};
</script>